


































































import Vue from "vue";
import create from "./components/create.vue";
import { getList, del, categoryAll } from "@/api/resource";
import moment from "moment";

export default Vue.extend({
  name: "ResourceFirst",
  filters: {
    timeFilter(value: string) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  components: {
    create
  },
  data() {
    return {
      form: {},
      typeList: [],
      list: [],
      page: {
        current: 1,
        size: 10
      },
      total: 0
    };
  },
  created() {
    this.getList();
    this.categoryAll();
  },
  methods: {
    handleSizeChange(val: number) {
      this.page.size = val;
      this.page.current = 1;
      this.getList();
    },
    handleCurrentChange(val: number) {
      this.page.current = val;
      this.getList();
    },
    indexMethod(index: number) {
      return (this.page.current - 1) * 10 + index + 1;
    },
    async getList() {
      const { data } = await getList(Object.assign(this.form, this.page));
      if (data.code === "000000") {
        this.list = data.data.records;
        this.total = data.data.total;
      } else {
        this.$message.error(data.mesg);
      }
    },
    async categoryAll() {
      const { data } = await categoryAll();
      if (data.code === "000000") {
        this.typeList = data.data;
      }
    },
    del(id: number) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await del(id);
          if (data.code === "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.mesg);
          }
        })
        .catch(() => {
          // 取消执行这里
        });
    }
  }
});
