






















import Vue from "vue";
import { Form } from "element-ui";
import { saveOrUpdateCategory } from "@/api/resource";

export default Vue.extend({
  name: "ResourceTypeAddOrUpdate",
  data() {
    return {
      form: {
        name: ""
      },
      rules: {
        name: [{ required: true, message: "请输入资源分类名称", trigger: "blur" }]
      },
      typeList: [],
      dialogFormVisible: false
    };
  },
  methods: {
    add() {
      this.form = {
        name: ""
      };
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        (this.$refs.ruleForm as Form).clearValidate();
      });
    },
    editor(row: any) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        (this.$refs.ruleForm as Form).clearValidate();
      });
    },
    submit() {
      (this.$refs.ruleForm as Form).validate(async (valid) => {
        if (valid) {
          const { data } = await saveOrUpdateCategory(this.form);
          if (data.code === "000000") {
            this.$message.success("保存成功");
            this.dialogFormVisible = false;
            this.$emit("ok");
          } else {
            this.$message.error(data.mesg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
});
