





























import Vue from "vue";
import create from "./components/createSecond.vue";
import { categoryAll, delCategory } from "@/api/resource";
import moment from "moment";

export default Vue.extend({
  name: "ResourceSecond",
  filters: {
    timeFilter(value: string) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  components: {
    create
  },
  data() {
    return {
      list: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index: number) {
      return index + 1;
    },
    async getList() {
      const { data } = await categoryAll();
      if (data.code === "000000") {
        this.list = data.data;
      } else {
        this.$message.error(data.mesg);
      }
    },
    del(id: number) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await delCategory(id);
          if (data.code === "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.mesg);
          }
        })
        .catch(() => {
          // 取消执行这里
        });
    }
  }
});
