













import Vue from "vue";
import First from "./first.vue";
import Second from "./second.vue";

export default Vue.extend({
  name: "ResourceIndex",
  components: {
    First,
    Second
  },
  data() {
    return {
      activeName: "first"
    };
  },
  methods: {
    handleClick(tab: any) {
      if (tab.name === "first") {
        (this.$refs.first as any).categoryAll();
      }
    }
  }
});
